import actions from './actions';
const {
  SS_RESET_STATE,
  SS_TOGGLE_VIEW_MODE,
  SS_SET_DIIRECTION,
  SS_SET_LIMIT,
  SS_SET_OFFSET,
  SS_SET_ORDER,
  SS_SET_SEARCH,
  SS_SET_TOTAL_COUNT,
} = actions;

const initialState = {
  totalCount: 0,
  viewMode: 'list',
  filters: {
    // order: 'id',
    // direction: 'desc',
    limit: 15,
    offset: 0,
  },
};

const crudWithServerPagingReducer = (state = initialState, action) => {
  const { type, data, err } = action;

  switch (type) {
    case SS_SET_DIIRECTION:
      return {
        ...state,
        filters: {
          ...state.filters,
          direction: data,
        },
      };
    case SS_SET_LIMIT:
      return {
        ...state,
        filters: {
          ...state.filters,
          limit: data,
        },
      };
    case SS_SET_OFFSET:
      return {
        ...state,
        filters: {
          ...state.filters,
          offset: data,
        },
      };
    case SS_SET_ORDER:
      return {
        ...state,
        filters: {
          ...state.filters,
          order: data,
        },
      };
    case SS_SET_SEARCH:
      return {
        ...state,
        filters: {
          ...state.filters,
          search: data,
        },
      };
    case SS_SET_TOTAL_COUNT:
      return {
        ...state,
        totalCount: data,
      };
    case SS_TOGGLE_VIEW_MODE:
      return {
        ...state,
        viewMode: data,
      };
    case SS_RESET_STATE:
      return {
        ...state,
        ...initialState,
      };
    default:
      return state;
  }
};

export default crudWithServerPagingReducer;
