/**
 * It's suggested to configure the RESTful endpoints in this file
 * so that there is only one source of truth, future update of endpoints
 * could be done from here without refactoring on multiple places throughout the app
 */
import config from '../../config/config';

const version = '1/';

const Addresses = () => ({
  identity: {
    login: `${config.apiBaseUrl()}auth/0.1/token`,
    logout: token => `${config.apiBaseUrl()}auth/0.1/token/${token}`,
  },

  tenant: {
    getAll: `${config.apiBaseUrl()}auth/0.1/tenants`,
    get: id => `${config.apiBaseUrl()}auth/0.1/tenants/${id}`,
    add: `${config.apiBaseUrl()}auth/0.1/tenants`,
    update: uuid => `${config.apiBaseUrl()}auth/0.1/tenants/${uuid}`,
    delete: uuid => `${config.apiBaseUrl()}auth/0.1/tenants/${uuid}`,
  },

  contexts: {
    getAll: `${config.apiBaseUrl()}confd/1.1/contexts`,
    get: id => `${config.apiBaseUrl()}confd/1.1/contexts/${id}`,
    add: `${config.apiBaseUrl()}confd/1.1/contexts`,
    update: id => `${config.apiBaseUrl()}confd/1.1/contexts/${id}`,
    includeContexts: id => `${config.apiBaseUrl()}confd/1.1/contexts/${id}/contexts`,
    delete: id => `${config.apiBaseUrl()}confd/1.1/contexts/${id}`,
  },

  devices: {
    getAll: `${config.apiBaseUrl()}confd/1.1/devices`,
    get: id => `${config.apiBaseUrl()}confd/1.1/devices/${id}`,
    add: `${config.apiBaseUrl()}confd/1.1/devices`,
    update: id => `${config.apiBaseUrl()}confd/1.1/devices/${id}`,
    delete: id => `${config.apiBaseUrl()}confd/1.1/devices/${id}`,
  },

  aggregateContexts: {
    add: `${config.apiBaseUrl()}${version}AggregateContexts`, // DNE
  },

  sip: {
    getAll: `${config.apiBaseUrl()}confd/1.1/endpoints/sip`,
    get: sipUUID => `${config.apiBaseUrl()}confd/1.1/endpoints/sip/${sipUUID}`,
    add: `${config.apiBaseUrl()}confd/1.1/endpoints/sip`,
    update: sipUUID => `${config.apiBaseUrl()}confd/1.1/endpoints/sip/${sipUUID}`,
    delete: sipUUID => `${config.apiBaseUrl()}confd/1.1/endpoints/sip/${sipUUID}`,
  },

  iax: {
    getAll: `${config.apiBaseUrl()}confd/1.1/endpoints/iax`,
    get: iaxID => `${config.apiBaseUrl()}confd/1.1/endpoints/iax/${iaxID}`,
    getRegister: registerID => `${config.apiBaseUrl()}confd/1.1/registers/iax/${registerID}`,
    add: `${config.apiBaseUrl()}confd/1.1/endpoints/iax`,
    addRegister: `${config.apiBaseUrl()}confd/1.1/registers/iax`,
    update: iaxID => `${config.apiBaseUrl()}confd/1.1/endpoints/iax/${iaxID}`,
    updateRegister: registerID => `${config.apiBaseUrl()}confd/1.1/registers/iax/${registerID}`,
    delete: iaxID => `${config.apiBaseUrl()}confd/1.1/endpoints/iax/${iaxID}`,
    deleteRegister: registerID => `${config.apiBaseUrl()}confd/1.1/registers/iax/${registerID}`,
  },

  custom: {
    getAll: `${config.apiBaseUrl()}confd/1.1/endpoints/custom`,
    get: iaxID => `${config.apiBaseUrl()}confd/1.1/endpoints/custom/${iaxID}`,
    add: `${config.apiBaseUrl()}confd/1.1/endpoints/custom`,
    update: iaxID => `${config.apiBaseUrl()}confd/1.1/endpoints/custom/${iaxID}`,
    delete: iaxID => `${config.apiBaseUrl()}confd/1.1/endpoints/custom/${iaxID}`,
  },

  sipTemplate: {
    getAll: `${config.apiBaseUrl()}confd/1.1/endpoints/sip/templates`,
    get: templateUuid => `${config.apiBaseUrl()}confd/1.1/endpoints/sip/templates/${templateUuid}`,
    add: `${config.apiBaseUrl()}confd/1.1/endpoints/sip/templates`,
    update: templateUuid => `${config.apiBaseUrl()}confd/1.1/endpoints/sip/templates/${templateUuid}`,
    delete: templateUuid => `${config.apiBaseUrl()}confd/1.1/endpoints/sip/templates/${templateUuid}`,
  },

  sipTransport: {
    getAll: `${config.apiBaseUrl()}confd/1.1/sip/transports`,
    get: sipUuid => `${config.apiBaseUrl()}confd/1.1/sip/transports/${sipUuid}`,
    add: `${config.apiBaseUrl()}confd/1.1/sip/transports`,
    update: sipUuid => `${config.apiBaseUrl()}confd/1.1/sip/transports/${sipUuid}`,
    delete: sipUuid => `${config.apiBaseUrl()}confd/1.1/sip/transports/${sipUuid}`,
  },

  trunksProviders: {
    getAll: `${config.apiBaseUrl()}confd/1.1/trunk/provider/`,
    get: providerName => `${config.apiBaseUrl()}confd/1.1/trunk/provider/${providerName}`,
    add: providerName => `${config.apiBaseUrl()}confd/1.1/trunk/provider/${providerName}`,
    update: providerName => `${config.apiBaseUrl()}confd/1.1/trunk/provider/${providerName}`,
    delete: providerName => `${config.apiBaseUrl()}confd/1.1/trunk/provider/${providerName}`,
  },

  conference: {
    getAll: `${config.apiBaseUrl()}confd/1.1/conferences`,
    get: id => `${config.apiBaseUrl()}confd/1.1/conferences/${id}`,
    add: `${config.apiBaseUrl()}confd/1.1/conferences`,
    update: id => `${config.apiBaseUrl()}confd/1.1/conferences/${id}`,
    delete: conferenceUuid => `${config.apiBaseUrl()}confd/1.1/conferences/${conferenceUuid}`,
  },

  conferenceConfig: {
    getAll: `${config.apiBaseUrl()}confd/1.1/conferences`,
    get: id => `${config.apiBaseUrl()}confd/1.1/conferences/${id}`,
    add: `${config.apiBaseUrl()}confd/1.1/conferences`,
    update: id => `${config.apiBaseUrl()}confd/1.1/conferences/${id}`,
    delete: id => `${config.apiBaseUrl()}confd/1.1/conferences/${id}`,
  },

  incalls: {
    getAll: `${config.apiBaseUrl()}confd/1.1/incalls`,
    get: id => `${config.apiBaseUrl()}confd/1.1/incalls/${id}`,
    add: `${config.apiBaseUrl()}confd/1.1/incalls`,
    update: id => `${config.apiBaseUrl()}confd/1.1/incalls/${id}`,
    delete: id => `${config.apiBaseUrl()}confd/1.1/incalls/${id}`,
    updateExtensionAssociate: ids => `${config.apiBaseUrl()}confd/${version}incalls/${ids[0]}/extensions/${ids[1]}`,
    deleteExtensionAssociate: ids => `${config.apiBaseUrl()}confd/${version}incalls/${ids[0]}/extensions/${ids[1]}`,
  },

  lines: {
    getAll: `${config.apiBaseUrl()}confd/1.1/lines`,
    get: id => `${config.apiBaseUrl()}confd/1.1/lines/${id}`,
    add: `${config.apiBaseUrl()}confd/1.1/lines`,
    update: id => `${config.apiBaseUrl()}confd/1.1/lines/${id}`,
    delete: id => `${config.apiBaseUrl()}confd/1.1/lines/${id}`,
  },

  extension: {
    getAll: `${config.apiBaseUrl()}confd/1.1/extensions`,
    getFeatures: `${config.apiBaseUrl()}confd/1.1/extensions/features`,
    get: id => `${config.apiBaseUrl()}confd/1.1/extensions/${id}`,
    add: `${config.apiBaseUrl()}confd/1.1/extensions`,
    update: id => `${config.apiBaseUrl()}confd/1.1/extensions/${id}`,
    updateFeature: id => `${config.apiBaseUrl()}confd/1.1/extensions/features/${id}`,
    delete: id => `${config.apiBaseUrl()}confd/1.1/extensions/${id}`,
  },

  incallExtensionAssociate: {
    update: (incallId, extensionId) => `${config.apiBaseUrl()}confd/1.1/incalls/${incallId}/extensions/${extensionId}`,
    delete: (incallId, extensionId) => `${config.apiBaseUrl()}confd/1.1/incalls/${incallId}/extensions/${extensionId}`,
  },

  groupExtensionAssociate: {
    update: (groupUUID, extensionID) => `${config.apiBaseUrl()}confd/1.1/groups/${groupUUID}/extensions/${extensionID}`,
    delete: (groupUUID, extensionID) => `${config.apiBaseUrl()}confd/1.1/groups/${groupUUID}/extensions/${extensionID}`,
  },

  queueExtensionAssociate: {
    update: (queueID, extensionID) => `${config.apiBaseUrl()}confd/1.1/queues/${queueID}/extensions/${extensionID}`,
    delete: (queueID, extensionID) => `${config.apiBaseUrl()}confd/1.1/queues/${queueID}/extensions/${extensionID}`,
  },

  conferenceExtensionAssociate: {
    update: (conferenceId, extensionId) =>
      `${config.apiBaseUrl()}confd/1.1/conferences/${conferenceId}/extensions/${extensionId}`,
    delete: (conferenceId, extensionId) =>
      `${config.apiBaseUrl()}confd/1.1/conferences/${conferenceId}/extensions/${extensionId}`,
  },

  scheduleIncallAssociate: {
    update: (incallId, scheduleId) => `${config.apiBaseUrl()}confd/1.1/incalls/${incallId}/schedules/${scheduleId}`,
    delete: (incallId, scheduleId) => `${config.apiBaseUrl()}confd/1.1/incalls/${incallId}/schedules/${scheduleId}`,
  },

  scheduleQueueAssociate: {
    update: (queueID, scheduleID) => `${config.apiBaseUrl()}confd/1.1/queues/${queueID}/schedules/${scheduleID}`,
    delete: (queueID, scheduleID) => `${config.apiBaseUrl()}confd/1.1/queues/${queueID}/schedules/${scheduleID}`,
  },

  trunkSipAssociate: {
    update: (trunkID, sipUUID) => `${config.apiBaseUrl()}confd/1.1/trunks/${trunkID}/endpoints/sip/${sipUUID}`,
    delete: (trunkID, sipUUID) => `${config.apiBaseUrl()}confd/1.1/trunks/${trunkID}/endpoints/sip/${sipUUID}`,
  },

  trunkIaxAssociate: {
    update: (trunkID, iaxID) => `${config.apiBaseUrl()}confd/1.1/trunks/${trunkID}/endpoints/iax/${iaxID}`,
    delete: (trunkID, iaxID) => `${config.apiBaseUrl()}confd/1.1/trunks/${trunkID}/endpoints/iax/${iaxID}`,
  },

  trunkRegisterIaxAssociate: {
    update: (trunkID, iaxID) => `${config.apiBaseUrl()}confd/1.1/trunks/${trunkID}/registers/iax/${iaxID}`,
    delete: (trunkID, iaxID) => `${config.apiBaseUrl()}confd/1.1/trunks/${trunkID}/registers/iax/${iaxID}`,
  },

  trunkCustomAssociate: {
    update: (trunkID, customID) => `${config.apiBaseUrl()}confd/1.1/trunks/${trunkID}/endpoints/custom/${customID}`,
    delete: (trunkID, customID) => `${config.apiBaseUrl()}confd/1.1/trunks/${trunkID}/endpoints/custom/${customID}`,
  },

  scheduleGroupAssociate: {
    update: (groupUUID, scheduleID) => `${config.apiBaseUrl()}confd/1.1/groups/${groupUUID}/schedules/${scheduleID}`,
    delete: (groupUUID, scheduleID) => `${config.apiBaseUrl()}confd/1.1/groups/${groupUUID}/schedules/${scheduleID}`,
  },

  queueAgentAssociate: {
    update: (queueID, agentID) => `${config.apiBaseUrl()}confd/1.1/queues/${queueID}/members/agents/${agentID}`,
    delete: (queueID, agentID) => `${config.apiBaseUrl()}confd/1.1/queues/${queueID}/members/agents/${agentID}`,
  },

  agentSkillAssociate: {
    update: (agentID, skillID) => `${config.apiBaseUrl()}confd/1.1/agents/${agentID}/skills/${skillID}`,
    delete: (agentID, skillID) => `${config.apiBaseUrl()}confd/1.1/agents/${agentID}/skills/${skillID}`,
  },

  queueUserAssociate: {
    update: (queueID, userID) => `${config.apiBaseUrl()}confd/1.1/queues/${queueID}/members/users/${userID}`,
    delete: (queueID, userID) => `${config.apiBaseUrl()}confd/1.1/queues/${queueID}/members/users/${userID}`,
  },

  groupUsersAssociate: {
    updateUsers: groupUUID => `${config.apiBaseUrl()}confd/1.1/groups/${groupUUID}/members/users`,
    delete: groupUUID => `${config.apiBaseUrl()}confd/1.1/groups/${groupUUID}/members/users`,
  },

  scheduleOutcallAssociate: {
    update: (outcallId, scheduleId) => `${config.apiBaseUrl()}confd/1.1/outcalls/${outcallId}/schedules/${scheduleId}`,
    delete: (outcallId, scheduleId) => `${config.apiBaseUrl()}confd/1.1/outcalls/${outcallId}/schedules/${scheduleId}`,
  },

  userScheduleAssociate: {
    update: (userID, scheduleId) => `${config.apiBaseUrl()}confd/1.1/users/${userID}/schedules/${scheduleId}`,
    delete: (userID, scheduleId) => `${config.apiBaseUrl()}confd/1.1/users/${userID}/schedules/${scheduleId}`,
  },

  outcalls: {
    getAll: `${config.apiBaseUrl()}confd/1.1/outcalls`,
    get: id => `${config.apiBaseUrl()}confd/1.1/outcalls/${id}`,
    add: `${config.apiBaseUrl()}confd/1.1/outcalls`,
    update: id => `${config.apiBaseUrl()}confd/1.1/outcalls/${id}`,
    delete: id => `${config.apiBaseUrl()}confd/1.1/outcalls/${id}`,
  },

  blackLists: {
    getAll: `${config.apiBaseUrl()}confd/1.1/blacklists`,
    get: id => `${config.apiBaseUrl()}confd/1.1/blacklists/${id}`,
    add: `${config.apiBaseUrl()}confd/1.1/blacklists`,
    update: id => `${config.apiBaseUrl()}confd/1.1/blacklists/${id}`,
    delete: id => `${config.apiBaseUrl()}confd/1.1/blacklists/${id}`,
  },

  CEL: {
    getAll: `${config.apiBaseUrl()}call-logd/1.0/cel`,
    get: id => `${config.apiBaseUrl()}call-logd/1.0/cel/${id}`,
  },

  documents: {
    getCustomerDocuments: customerUUID => `${config.apiBaseUrl()}confd/1.1/customers/${customerUUID}/documents`,
    getResellerDocuments: resellerUUID => `${config.apiBaseUrl()}confd/1.1/resellers/${resellerUUID}/documents`,
    get: documentUUID => `${config.apiBaseUrl()}confd/1.1/documents/${documentUUID}`,
    download: documentUUID => `${config.apiBaseUrl()}confd/1.1/documents/${documentUUID}/downloads`,
    addCustomerDocument: customerUUID => `${config.apiBaseUrl()}confd/1.1/customers/${customerUUID}/documents`,
    addResellerDocument: resellerUUID => `${config.apiBaseUrl()}confd/1.1/resellers/${resellerUUID}/documents`,
    update: documentUUID => `${config.apiBaseUrl()}confd/1.1/documents/${documentUUID}`,
    delete: documentUUID => `${config.apiBaseUrl()}confd/1.1/documents/${documentUUID}`,
  },

  contacts: {
    getAll: `${config.apiBaseUrl()}confd/1.1/contacts`,
    getCustomerContacts: customerUUID => `${config.apiBaseUrl()}confd/1.1/customers/${customerUUID}/contacts`,
    getResellerContacts: resellerUUID => `${config.apiBaseUrl()}confd/1.1/resellers/${resellerUUID}/contacts`,
    get: contactUUID => `${config.apiBaseUrl()}confd/1.1/contacts/${contactUUID}`,
    addCustomerContact: customerUUID => `${config.apiBaseUrl()}confd/1.1/customers/${customerUUID}/contacts`,
    addResellerContact: resellerUUID => `${config.apiBaseUrl()}confd/1.1/resellers/${resellerUUID}/contacts`,
    update: contactUUID => `${config.apiBaseUrl()}confd/1.1/contacts/${contactUUID}`,
    delete: contactUUID => `${config.apiBaseUrl()}confd/1.1/contacts/${contactUUID}`,
  },

  addresses: {
    getCustomerAddresses: customerUUID => `${config.apiBaseUrl()}confd/1.1/customers/${customerUUID}/address`,
    getResellerAddresses: resellerUUID => `${config.apiBaseUrl()}confd/1.1/resellers/${resellerUUID}/address`,
    getContactAddresses: contactUUID => `${config.apiBaseUrl()}confd/1.1/contacts/${contactUUID}/address`,
    get: addressUUID => `${config.apiBaseUrl()}confd/1.1/address/${addressUUID}`,
    addCustomerAddresses: customerUUID => `${config.apiBaseUrl()}confd/1.1/customers/${customerUUID}/address`,
    addResellerAddresses: resellerUUID => `${config.apiBaseUrl()}confd/1.1/resellers/${resellerUUID}/address`,
    addContactAddresses: resellerUUID => `${config.apiBaseUrl()}confd/1.1/contacts/${resellerUUID}/address`,
    update: contactUUID => `${config.apiBaseUrl()}confd/1.1/address/${contactUUID}`,
    delete: contactUUID => `${config.apiBaseUrl()}confd/1.1/address/${contactUUID}`,
  },

  resellers: {
    getAll: `${config.apiBaseUrl()}confd/1.1/resellers`,
    get: id => `${config.apiBaseUrl()}confd/1.1/resellers/${id}`,
    add: `${config.apiBaseUrl()}confd/1.1/resellers`,
    update: id => `${config.apiBaseUrl()}confd/1.1/resellers/${id}`,
    delete: id => `${config.apiBaseUrl()}confd/1.1/resellers/${id}`,
  },

  customers: {
    getAll: `${config.apiBaseUrl()}confd/1.1/customers`,
    get: id => `${config.apiBaseUrl()}confd/1.1/customers/${id}`,
    add: `${config.apiBaseUrl()}confd/1.1/customers`,
    update: id => `${config.apiBaseUrl()}confd/1.1/customers/${id}`,
    delete: id => `${config.apiBaseUrl()}confd/1.1/customers/${id}`,
  },

  plugins: {
    getMarketList: `${config.apiBaseUrl()}plugind/0.2/market`,
    getMarketInfo: (namespace, name) => `${config.apiBaseUrl()}plugind/0.2/market/${namespace}/${name}`,
    getInstalledList: `${config.apiBaseUrl()}plugind/0.2/plugins`,
    getInstalledInfo: (namespace, name) => `${config.apiBaseUrl()}plugind/0.2/plugins/${namespace}/${name}`,
    install: `${config.apiBaseUrl()}plugind/0.2/plugins`,
    uninstall: (namespace, name) => `${config.apiBaseUrl()}plugind/0.2/plugins/${namespace}/${name}`,
  },

  accessFeatures: {
    getAll: `${config.apiBaseUrl()}confd/1.1/access_features`,
    get: id => `${config.apiBaseUrl()}confd/1.1/access_features/${id}`,
    add: `${config.apiBaseUrl()}confd/1.1/access_features`,
    update: id => `${config.apiBaseUrl()}confd/1.1/access_features/${id}`,
    delete: id => `${config.apiBaseUrl()}confd/1.1/access_features/${id}`,
  },

  callfilters: {
    getAll: `${config.apiBaseUrl()}confd/1.1/callfilters`,
    get: id => `${config.apiBaseUrl()}confd/1.1/callfilters/${id}`,
    add: `${config.apiBaseUrl()}confd/1.1/callfilters`,
    update: id => `${config.apiBaseUrl()}confd/1.1/callfilters/${id}`,
    updateRecipients: id => `${config.apiBaseUrl()}confd/1.1/callfilters/${id}/recipients/users`,
    updateSurrogates: id => `${config.apiBaseUrl()}confd/1.1/callfilters/${id}/surrogates/users`,
    updateFallbacks: id => `${config.apiBaseUrl()}confd/1.1/callfilters/${id}/fallbacks`,
    delete: id => `${config.apiBaseUrl()}confd/1.1/callfilters/${id}`,
  },

  callpickups: {
    getAll: `${config.apiBaseUrl()}confd/1.1/callpickups`,
    get: id => `${config.apiBaseUrl()}confd/1.1/callpickups/${id}`,
    add: `${config.apiBaseUrl()}confd/1.1/callpickups`,
    update: id => `${config.apiBaseUrl()}confd/1.1/callpickups/${id}`,
    updateGroupInterceptors: id => `${config.apiBaseUrl()}confd/1.1/callpickups/${id}/interceptors/groups`,
    updateUserInterceptors: id => `${config.apiBaseUrl()}confd/1.1/callpickups/${id}/interceptors/users`,
    updateGroupTargets: id => `${config.apiBaseUrl()}confd/1.1/callpickups/${id}/targets/groups`,
    updateUserTargets: id => `${config.apiBaseUrl()}confd/1.1/callpickups/${id}/targets/users`,
    delete: id => `${config.apiBaseUrl()}confd/1.1/callpickups/${id}`,
  },

  trunks: {
    getAll: `${config.apiBaseUrl()}confd/1.1/trunks`,
    get: id => `${config.apiBaseUrl()}confd/1.1/trunks/${id}`,
    add: `${config.apiBaseUrl()}confd/1.1/trunks`,
    addByProviderName: `${config.apiBaseUrl()}confd/1.1/trunk/provider/new/`,
    update: id => `${config.apiBaseUrl()}confd/1.1/trunks/${id}`,
    delete: id => `${config.apiBaseUrl()}confd/1.1/trunks/${id}`,
  },

  sessions: {
    getAll: `${config.apiBaseUrl()}auth/0.1/sessions`,
    get: uuid => `${config.apiBaseUrl()}auth/0.1/sessions/${uuid}`,
    delete: uuid => `${config.apiBaseUrl()}auth/0.1/sessions/${uuid}`,
    deleteUserSession: (userUUID, sessionUUID) =>
      `${config.apiBaseUrl()}auth/0.1/users/${userUUID}/sessions/${sessionUUID}`,
  },

  callpermissions: {
    getAll: `${config.apiBaseUrl()}confd/1.1/callpermissions`,
    get: id => `${config.apiBaseUrl()}confd/1.1/callpermissions/${id}`,
    add: `${config.apiBaseUrl()}confd/1.1/callpermissions`,
    update: id => `${config.apiBaseUrl()}confd/1.1/callpermissions/${id}`,
    delete: id => `${config.apiBaseUrl()}confd/1.1/callpermissions/${id}`,
  },

  outcallExtensionAssociate: {
    update: (outcallId, extensionId) =>
      `${config.apiBaseUrl()}confd/1.1/outcalls/${outcallId}/extensions/${extensionId}`,
    delete: (outcallId, extensionId) =>
      `${config.apiBaseUrl()}confd/1.1/outcalls/${outcallId}/extensions/${extensionId}`,
  },

  outcallPermissionAssociate: {
    update: (outcallID, permissionID) =>
      `${config.apiBaseUrl()}confd/1.1/outcalls/${outcallID}/callpermissions/${permissionID}`,
    delete: (outcallID, permissionID) =>
      `${config.apiBaseUrl()}confd/1.1/outcalls/${outcallID}/callpermissions/${permissionID}`,
  },

  trunkAndOutcallAssociate: {
    update: outcallId => `${config.apiBaseUrl()}confd/1.1/outcalls/${outcallId}/trunks`,
  },

  schedules: {
    getAll: `${config.apiBaseUrl()}confd/1.1/schedules`,
    get: id => `${config.apiBaseUrl()}confd/1.1/schedules/${id}`,
    add: `${config.apiBaseUrl()}confd/1.1/schedules`,
    update: id => `${config.apiBaseUrl()}confd/1.1/schedules/${id}`,
    delete: id => `${config.apiBaseUrl()}confd/1.1/schedules/${id}`,
  },

  users: {
    massImport: `${config.apiBaseUrl()}confd/1.1/users/import`,
    authGetAll: `${config.apiBaseUrl()}auth/0.1/users`,
    confGetAll: `${config.apiBaseUrl()}confd/1.1/users`,
    authGetACL: `${config.apiBaseUrl()}auth/0.1/policies`,
    authGetUserACL: uuid => `${config.apiBaseUrl()}auth/0.1/users/${uuid}/policies`,
    authGetGroups: `${config.apiBaseUrl()}auth/0.1/groups`,
    authGetUserGroups: uuid => `${config.apiBaseUrl()}auth/0.1/groups/${uuid}/user`,
    confGet: id => `${config.apiBaseUrl()}confd/1.1/users/${id}`,
    authGet: uuid => `${config.apiBaseUrl()}auth/0.1/users/${uuid}`,
    updateFallbacks: id => `${config.apiBaseUrl()}confd/1.1/users/${id}/fallbacks`,
    updateServices: id => `${config.apiBaseUrl()}confd/1.1/users/${id}/services`,
    updateForwards: id => `${config.apiBaseUrl()}confd/1.1/users/${id}/forwards`,
    updateGroups: id => `${config.apiBaseUrl()}confd/1.1/users/${id}/groups`,
    getDetails: `${config.apiBaseUrl()}identity/user`, // DNE
    getRoles: id => `${config.apiBaseUrl()}identity/user/roles/${id}`, // DNE
    confirmEmail: `${config.apiBaseUrl()}identity/user/confirm-email`, // DNE
    export: `${config.apiBaseUrl()}confd/1.1/users/export`,
    confAdd: `${config.apiBaseUrl()}confd/1.1/users`,
    authAdd: `${config.apiBaseUrl()}auth/0.1/users`,
    register: `${config.apiBaseUrl()}auth/0.1/users/register`,
    toggleStatus: `${config.apiBaseUrl()}identity/user/toggle-status`, // DNE
    forgotPassword: `${config.apiBaseUrl()}identity/user/forgot-password`, // DNE
    resetPassword: `${config.apiBaseUrl()}auth/0.1/users/password/reset`,
    confUpdate: id => `${config.apiBaseUrl()}confd/1.1/users/${id}`,
    authUpdate: uuid => `${config.apiBaseUrl()}auth/0.1/users/${uuid}`,
    updateRoles: id => `${config.apiBaseUrl()}identity/user/roles/${id}`, // DNE
    confDelete: id => `${config.apiBaseUrl()}confd/1.1/users/${id}`,
    authDelete: uuid => `${config.apiBaseUrl()}auth/0.1/users/${uuid}`,
    updateAuthPassword: uuid => `${config.apiBaseUrl()}auth/0.1/users/${uuid}/password`,
    updateAuthEmails: uuid => `${config.apiBaseUrl()}auth/0.1/users/${uuid}/emails`,
  },
  userPolicyAssociation: {
    update: (user_uuid, policy_uui) => `${config.apiBaseUrl()}auth/0.1/users/${user_uuid}/policies/${policy_uui}`,
    delete: (user_uuid, policy_uui) => `${config.apiBaseUrl()}auth/0.1/users/${user_uuid}/policies/${policy_uui}`,
  },

  userGroupsAssociation: {
    update: (user_uuid, groups_uuid) => `${config.apiBaseUrl()}auth/0.1/groups/${groups_uuid}/users/${user_uuid}`,
    delete: (user_uuid, groups_uuid) => `${config.apiBaseUrl()}auth/0.1/groups/${groups_uuid}/users/${user_uuid}`,
  },

  voicemail: {
    getAll: `${config.apiBaseUrl()}confd/1.1/voicemails`,
    get: id => `${config.apiBaseUrl()}confd/1.1/voicemails/${id}`,
    add: `${config.apiBaseUrl()}confd/1.1/voicemails`,
    update: id => `${config.apiBaseUrl()}confd/1.1/voicemails/${id}`,
    delete: id => `${config.apiBaseUrl()}confd/1.1/voicemails/${id}`,
    zonemessages: `${config.apiBaseUrl()}confd/1.1/asterisk/voicemail/zonemessages`,
  },

  voicemailUserAssociate: {
    update: (userID, voicemailID) => `${config.apiBaseUrl()}confd/1.1/users/${userID}/voicemails/${voicemailID}`,
    delete: userID => `${config.apiBaseUrl()}confd/1.1/users/${userID}/voicemails`,
  },

  userCallpermissionAssociate: {
    update: (userID, callpermissionID) =>
      `${config.apiBaseUrl()}confd/1.1/users/${userID}/callpermissions/${callpermissionID}`,
    delete: (userID, callpermissionID) =>
      `${config.apiBaseUrl()}confd/1.1/users/${userID}/callpermissions/${callpermissionID}`,
  },

  groupCallpermissionAssociate: {
    update: (groupUUID, callpermissionID) =>
      `${config.apiBaseUrl()}confd/1.1/groups/${groupUUID}/callpermissions/${callpermissionID}`,
    delete: (groupUUID, callpermissionID) =>
      `${config.apiBaseUrl()}confd/1.1/groups/${groupUUID}/callpermissions/${callpermissionID}`,
  },

  lineUserAssociate: {
    associateUserAndLine: (userID, lineID) => `${config.apiBaseUrl()}confd/1.1/users/${userID}/lines/${lineID}`,
    associateUserAndLines: userID => `${config.apiBaseUrl()}confd/1.1/users/${userID}/lines`,
    disassociateUserAndLine: (userID, lineID) => `${config.apiBaseUrl()}confd/1.1/users/${userID}/lines/${lineID}`,
  },

  lineExtensionAssociate: {
    associateLineAndExtension: (lineID, extensionID) =>
      `${config.apiBaseUrl()}confd/1.1/lines/${lineID}/extensions/${extensionID}`,
    disassociateLineAndExtension: (lineID, extensionID) =>
      `${config.apiBaseUrl()}confd/1.1/lines/${lineID}/extensions/${extensionID}`,
  },

  parkingLotExtensionAssociate: {
    associateParkingLotAndExtension: (parkingLotID, extensionID) =>
      `${config.apiBaseUrl()}confd/1.1/parkinglots/${parkingLotID}/extensions/${extensionID}`,
    disassociateParkingLotAndExtension: (parkingLotID, extensionID) =>
      `${config.apiBaseUrl()}confd/1.1/parkinglots/${parkingLotID}/extensions/${extensionID}`,
  },

  lineSipAssociate: {
    associateLineAndSip: (lineID, sipUUID) =>
      `${config.apiBaseUrl()}confd/1.1/lines/${lineID}/endpoints/sip/${sipUUID}`,
    disassociateLineAndSip: (lineID, sipUUID) =>
      `${config.apiBaseUrl()}confd/1.1/lines/${lineID}/endpoints/sip/${sipUUID}`,
  },

  lineDeviceAssociate: {
    associateLineAndDevice: (lineID, deviceID) => `${config.apiBaseUrl()}confd/1.1/lines/${lineID}/devices/${deviceID}`,
    disassociateLineAndDevice: (lineID, deviceID) =>
      `${config.apiBaseUrl()}confd/1.1/lines/${lineID}/devices/${deviceID}`,
  },

  lineApplicationAssociate: {
    associateLineAndApplication: (lineID, applicationID) =>
      `${config.apiBaseUrl()}confd/1.1/lines/${lineID}/applications/${applicationID}`,
    disassociateLineAndApplication: (lineID, applicationID) =>
      `${config.apiBaseUrl()}confd/1.1/lines/${lineID}/applications/${applicationID}`,
  },

  visualBuilder: {
    getAll: `${config.apiBaseUrl()}confd/1.1/visualbuilders`,
    get: id => `${config.apiBaseUrl()}confd/1.1/visualbuilders/${id}`,
    add: `${config.apiBaseUrl()}confd/1.1/visualbuilders`,
    update: id => `${config.apiBaseUrl()}confd/1.1/visualbuilders/${id}`,
    delete: id => `${config.apiBaseUrl()}confd/1.1/visualbuilders/${id}`,
  },

  trunk: {
    getAll: `${config.apiBaseUrl()}confd/1.1/trunks`,
  },

  sounds: {
    getAll: `${config.apiBaseUrl()}confd/1.1/sounds`,
    getLanguages: `${config.apiBaseUrl()}confd/1.1/sounds/languages`,

    get: soundCategory => `${config.apiBaseUrl()}confd/1.1/sounds/${soundCategory}`,
    getFile: (soundCategory, soundFileName) =>
      `${config.apiBaseUrl()}confd/1.1/sounds/${soundCategory}/files/${soundFileName}`,
    add: `${config.apiBaseUrl()}confd/1.1/sounds`,
    update: (soundCategory, soundFilename) =>
      `${config.apiBaseUrl()}confd/1.1/sounds/${soundCategory}/files/${soundFilename}`,
    delete: soundCategory => `${config.apiBaseUrl()}confd/1.1/sounds/${soundCategory}`,
    deleteFile: (soundCategory, soundFileName) =>
      `${config.apiBaseUrl()}confd/1.1/sounds/${soundCategory}/files/${soundFileName}`,
  },

  musics: {
    getAll: `${config.apiBaseUrl()}confd/1.1/moh`,
    get: mohUUID => `${config.apiBaseUrl()}confd/1.1/moh/${mohUUID}`,
    getFile: (mohUUID, mohFileName) => `${config.apiBaseUrl()}confd/1.1/moh/${mohUUID}/files/${mohFileName}`,
    add: `${config.apiBaseUrl()}confd/1.1/moh`,
    update: mohUUID => `${config.apiBaseUrl()}confd/1.1/moh/${mohUUID}`,
    updateFile: (mohUUID, mohFilename) => `${config.apiBaseUrl()}confd/1.1/moh/${mohUUID}/files/${mohFilename}`,
    delete: mohUUID => `${config.apiBaseUrl()}confd/1.1/moh/${mohUUID}`,
    deleteFile: (mohUUID, mohFileName) => `${config.apiBaseUrl()}confd/1.1/moh/${mohUUID}/files/${mohFileName}`,
  },

  groups: {
    getAll: `${config.apiBaseUrl()}confd/1.1/groups`,
    get: groupUUID => `${config.apiBaseUrl()}confd/1.1/groups/${groupUUID}`,
    getFallbacks: groupUUID => `${config.apiBaseUrl()}confd/1.1/groups/${groupUUID}/fallbacks`,
    add: `${config.apiBaseUrl()}confd/1.1/groups`,
    update: groupUUID => `${config.apiBaseUrl()}confd/1.1/groups/${groupUUID}`,
    updateFallbacks: groupUUID => `${config.apiBaseUrl()}confd/1.1/groups/${groupUUID}/fallbacks`,
    delete: groupUUID => `${config.apiBaseUrl()}confd/1.1/groups/${groupUUID}`,
  },

  ivrs: {
    getAll: `${config.apiBaseUrl()}confd/1.1/ivr`,
    get: id => `${config.apiBaseUrl()}confd/1.1/ivr/${id}`,
    add: `${config.apiBaseUrl()}confd/1.1/ivr`,
    update: id => `${config.apiBaseUrl()}confd/1.1/ivr/${id}`,
    delete: id => `${config.apiBaseUrl()}confd/1.1/ivr/${id}`,
  },

  licenses: {
    getAll: `${config.apiBaseUrl()}cino/${version}licenses`,
    get: id => `${config.apiBaseUrl()}cino/${version}licenses/${id}`,
    add: `${config.apiBaseUrl()}cino/${version}licenses`,
    update: id => `${config.apiBaseUrl()}cino/${version}licenses/${id}`,
    delete: id => `${config.apiBaseUrl()}cino/${version}licenses/${id}`,
  },

  licenseCategories: {
    getAll: `${config.apiBaseUrl()}cino/${version}licensecategories`,
    get: id => `${config.apiBaseUrl()}cino/${version}licensecategories/${id}`,
    add: `${config.apiBaseUrl()}cino/${version}licensecategories`,
    update: id => `${config.apiBaseUrl()}cino/${version}licensecategories/${id}`,
    delete: id => `${config.apiBaseUrl()}cino/${version}licensecategories/${id}`,
  },

  licenseTypes: {
    getAll: `${config.apiBaseUrl()}cino/${version}licensetypes`,
    get: id => `${config.apiBaseUrl()}cino/${version}licensetypes/${id}`,
    add: `${config.apiBaseUrl()}cino/${version}licensetypes`,
    update: id => `${config.apiBaseUrl()}cino/${version}licensetypes/${id}`,
    delete: id => `${config.apiBaseUrl()}cino/${version}licensetypes/${id}`,
  },

  queues: {
    getAll: `${config.apiBaseUrl()}confd/1.1/queues`,
    get: id => `${config.apiBaseUrl()}confd/1.1/queues/${id}`,
    getFallbacks: queueID => `${config.apiBaseUrl()}confd/1.1/queues/${queueID}/fallbacks`,
    add: `${config.apiBaseUrl()}confd/1.1/queues`,
    update: id => `${config.apiBaseUrl()}confd/1.1/queues/${id}`,
    updateFallbacks: queueID => `${config.apiBaseUrl()}confd/1.1/queues/${queueID}/fallbacks`,
    delete: id => `${config.apiBaseUrl()}confd/1.1/queues/${id}`,
  },

  backendConfigurations: {
    getAllSources: `${config.apiBaseUrl()}dird/0.1/sources`,
    getAll: type => `${config.apiBaseUrl()}dird/0.1/backends/${type}/sources`,
    get: (type, id) => `${config.apiBaseUrl()}dird/0.1/backends/${type}/sources/${id}`,
    add: type => `${config.apiBaseUrl()}dird/0.1/backends/${type}/sources`,
    update: (type, id) => `${config.apiBaseUrl()}dird/0.1/backends/${type}/sources/${id}`,
    delete: (type, id) => `${config.apiBaseUrl()}dird/0.1/backends/${type}/sources/${id}`,
  },

  switchboards: {
    getAll: `${config.apiBaseUrl()}confd/1.1/switchboards`,
    get: id => `${config.apiBaseUrl()}confd/1.1/switchboards/${id}`,
    add: `${config.apiBaseUrl()}confd/1.1/switchboards`,
    addUser: switchboardUUID => `${config.apiBaseUrl()}confd/1.1/switchboards/${switchboardUUID}/members/users`,
    updateFallback: switchboardUUID => `${config.apiBaseUrl()}confd/1.1/switchboards/${switchboardUUID}/fallbacks`,
    update: id => `${config.apiBaseUrl()}confd/1.1/switchboards/${id}`,
    delete: id => `${config.apiBaseUrl()}confd/1.1/switchboards/${id}`,
  },

  skillRules: {
    getAll: `${config.apiBaseUrl()}confd/1.1/queues/skillrules`,
    get: id => `${config.apiBaseUrl()}confd/1.1/queues/skillrules/${id}`,
    add: `${config.apiBaseUrl()}confd/1.1/queues/skillrules`,
    update: id => `${config.apiBaseUrl()}confd/1.1/queues/skillrules/${id}`,
    delete: id => `${config.apiBaseUrl()}confd/1.1/queues/skillrules/${id}`,
  },

  skills: {
    getAll: `${config.apiBaseUrl()}confd/1.1/agents/skills`,
    get: id => `${config.apiBaseUrl()}confd/1.1/agents/skills/${id}`,
    add: `${config.apiBaseUrl()}confd/1.1/agents/skills`,
    update: id => `${config.apiBaseUrl()}confd/1.1/agents/skills/${id}`,
    delete: id => `${config.apiBaseUrl()}confd/1.1/agents/skills/${id}`,
  },

  configurationProfiles: {
    getAll: `${config.apiBaseUrl()}dird/0.1/profiles`,
    get: id => `${config.apiBaseUrl()}dird/0.1/profiles/${id}`,
    add: `${config.apiBaseUrl()}dird/0.1/profiles`,
    update: id => `${config.apiBaseUrl()}dird/0.1/profiles/${id}`,
    delete: id => `${config.apiBaseUrl()}dird/0.1/profiles/${id}`,
  },

  phonebooks: {
    getAll: () => `${config.apiBaseUrl()}dird/0.1/phonebooks`,
    get: phonebookID => `${config.apiBaseUrl()}dird/0.1/phonebooks/${phonebookID}`,
    add: () => `${config.apiBaseUrl()}dird/0.1/phonebooks`,
    update: phonebookID => `${config.apiBaseUrl()}dird/0.1/phonebooks/${phonebookID}`,
    delete: phonebookID => `${config.apiBaseUrl()}dird/0.1/phonebooks/${phonebookID}`,
  },

  phonebookContacts: {
    getAll: phonebookID => `${config.apiBaseUrl()}dird/0.1/phonebooks/${phonebookID}/contacts`,
    get: (phonebookID, contactID) => `${config.apiBaseUrl()}dird/0.1/phonebooks/${phonebookID}/contacts/${contactID}`,
    add: phonebookID => `${config.apiBaseUrl()}dird/0.1/phonebooks/${phonebookID}/contacts`,
    import: phonebookID => `${config.apiBaseUrl()}dird/0.1/phonebooks/${phonebookID}/contacts/import`,
    update: (phonebookID, contactID) =>
      `${config.apiBaseUrl()}dird/0.1/phonebooks/${phonebookID}/contacts/${contactID}`,
    delete: (phonebookID, contactID) =>
      `${config.apiBaseUrl()}dird/0.1/phonebooks/${phonebookID}/contacts/${contactID}`,
  },

  parkingLots: {
    getAll: `${config.apiBaseUrl()}confd/1.1/parkinglots`,
    get: id => `${config.apiBaseUrl()}confd/1.1/parkinglots/${id}`,
    add: `${config.apiBaseUrl()}confd/1.1/parkinglots`,
    update: id => `${config.apiBaseUrl()}confd/1.1/parkinglots/${id}`,
    delete: id => `${config.apiBaseUrl()}confd/1.1/parkinglots/${id}`,
  },

  cdr: {
    getAll: `${config.apiBaseUrl()}call-logd/1.0/cdr`,
    get: id => `${config.apiBaseUrl()}call-logd/1.0/cdr/${id}`,
    getRecordingMedia: (cdrID, recordingUUID) =>
      `${config.apiBaseUrl()}call-logd/1.0/cdr/${cdrID}/recordings/${recordingUUID}/media`,
    export: `${config.apiBaseUrl()}call-logd/1.0/cdr/recordings/media/export`,
    delete: `${config.apiBaseUrl()}call-logd/1.0/cdr/recordings/media`,
    deleteRecording: (cdrID, recordingUUID) =>
      `${config.apiBaseUrl()}call-logd/1.0/cdr/${cdrID}/recordings/${recordingUUID}/media`,
  },

  agentStatistic: {
    getAll: `${config.apiBaseUrl()}call-logd/1.0/agents/statistics`,
    get: id => `${config.apiBaseUrl()}call-logd/1.0/agents/${id}/statistics`,
  },

  queueStatistic: {
    getAll: `${config.apiBaseUrl()}call-logd/1.0/queues/statistics`,
    get: id => `${config.apiBaseUrl()}call-logd/1.0/queues/${id}/statistics`,
    getQoS: id => `${config.apiBaseUrl()}call-logd/1.0/queues/${id}/statistics/qos`,
  },

  agents: {
    getAll: `${config.apiBaseUrl()}confd/1.1/agents`,
    get: id => `${config.apiBaseUrl()}confd/1.1/agents/${id}`,
    add: `${config.apiBaseUrl()}confd/1.1/agents`,
    update: id => `${config.apiBaseUrl()}confd/1.1/agents/${id}`,
    delete: id => `${config.apiBaseUrl()}confd/1.1/agents/${id}`,
  },

  ingresses: {
    getAll: `${config.apiBaseUrl()}confd/1.1/ingresses/http`,
    get: uuid => `${config.apiBaseUrl()}confd/1.1/ingresses/http/${uuid}`,
    add: `${config.apiBaseUrl()}confd/1.1/ingresses/http`,
    update: uuid => `${config.apiBaseUrl()}confd/1.1/ingresses/http/${uuid}`,
    delete: uuid => `${config.apiBaseUrl()}confd/1.1/ingresses/http/${uuid}`,
  },

  meetings: {
    getAll: `${config.apiBaseUrl()}confd/1.1/meetings`,
    get: uuid => `${config.apiBaseUrl()}confd/1.1/meetings/${uuid}`,
    add: `${config.apiBaseUrl()}confd/1.1/meetings`,
    update: uuid => `${config.apiBaseUrl()}confd/1.1/meetings/${uuid}`,
    delete: uuid => `${config.apiBaseUrl()}confd/1.1/meetings/${uuid}`,
  },

  queueFeature: {
    getAll: `${config.apiBaseUrl()}confd/1.1/queue-features`,
    get: uuid => `${config.apiBaseUrl()}confd/1.1/queue-features/${uuid}`,
    add: `${config.apiBaseUrl()}confd/1.1/queue-features`,
    update: uuid => `${config.apiBaseUrl()}confd/1.1/queue-features/${uuid}`,
    delete: uuid => `${config.apiBaseUrl()}confd/1.1/queue-features/${uuid}`,
  },

  surveys: {
    getAll: `${config.apiBaseUrl()}confd/1.1/surveys`,
    agentSurveys: agentNumber => `${config.apiBaseUrl()}confd/1.1/surveys/agent/${agentNumber}`,
    agentAverageSurvey: agentNumber => `${config.apiBaseUrl()}confd/1.1/surveys/average/agent/${agentNumber}`,
    allAgentsAverageSurveys: `${config.apiBaseUrl()}confd/1.1/surveys/average/allagent`,
    allQueuesAverageSurveys: `${config.apiBaseUrl()}confd/1.1/surveys/average/allqueue`,
    queueAverageSurvey: queueID => `${config.apiBaseUrl()}confd/1.1/surveys/average/queue/${queueID}`,
    agentAverageSurveyByQueue: (queueID, agentNumber) =>
      `${config.apiBaseUrl()}confd/1.1/surveys/average/queue/${queueID}/agent/${agentNumber}`,
    queueSurveys: queueID => `${config.apiBaseUrl()}confd/1.1/surveys/queue/${queueID}`,
  },

  policies: {
    getAll: `${config.apiBaseUrl()}auth/0.1/policies`,
    get: uuid => `${config.apiBaseUrl()}auth/0.1/policies/${uuid}`,
    add: `${config.apiBaseUrl()}auth/0.1/policies`,
    update: uuid => `${config.apiBaseUrl()}auth/0.1/policies/${uuid}`,
    delete: uuid => `${config.apiBaseUrl()}auth/0.1/policies/${uuid}`,
  },

  callInfos: {
    getAll: `${config.apiBaseUrl()}confd/1.1/call_infos`,
    get: uuid => `${config.apiBaseUrl()}confd/1.1/call_infos/${uuid}`,
    add: `${config.apiBaseUrl()}confd/1.1/call_infos`,
    update: uuid => `${config.apiBaseUrl()}confd/1.1/call_infos/${uuid}`,
    delete: uuid => `${config.apiBaseUrl()}confd/1.1/call_infos/${uuid}`,
  },
  tags: {
    getAll: `${config.apiBaseUrl()}confd/1.1/tags`,
    get: uuid => `${config.apiBaseUrl()}confd/1.1/tags/${uuid}`,
    add: `${config.apiBaseUrl()}confd/1.1/tags`,
    update: uuid => `${config.apiBaseUrl()}confd/1.1/tags/${uuid}`,
    delete: uuid => `${config.apiBaseUrl()}confd/1.1/tags/${uuid}`,
  },

  authGroups: {
    getAll: `${config.apiBaseUrl()}auth/0.1/groups`,
    get: uuid => `${config.apiBaseUrl()}auth/0.1/groups/${uuid}`,
    add: `${config.apiBaseUrl()}auth/0.1/groups`,
    update: uuid => `${config.apiBaseUrl()}auth/0.1/groups/${uuid}`,
    delete: uuid => `${config.apiBaseUrl()}auth/0.1/groups/${uuid}`,
    getUsers: uuid => `${config.apiBaseUrl()}auth/0.1/groups/${uuid}/users`,
    getPolices: uuid => `${config.apiBaseUrl()}auth/0.1/groups/${uuid}/policies`,
    associatePolicy: (groupUuid, policyId) => `${config.apiBaseUrl()}auth/0.1/groups/${groupUuid}/policies/${policyId}`,
    disassociatePolicy: (groupUuid, policyId) =>
      `${config.apiBaseUrl()}auth/0.1/groups/${groupUuid}/policies/${policyId}`,
    associateUser: (groupUuid, userId) => `${config.apiBaseUrl()}auth/0.1/groups/${groupUuid}/users/${userId}`,
    disassociateUser: (groupUuid, userId) => `${config.apiBaseUrl()}auth/0.1/groups/${groupUuid}/users/${userId}`,
  },

  provisioningPlugins: {
    getInstallable: `${config.apiBaseUrl()}provd/0.2/pg_mgr/install/installable`,
    getInstalled: `${config.apiBaseUrl()}provd/0.2/pg_mgr/install/installed`,
  },

  pagings: {
    getAll: `${config.apiBaseUrl()}confd/1.1/pagings`,
    get: id => `${config.apiBaseUrl()}confd/1.1/pagings/${id}`,
    add: `${config.apiBaseUrl()}confd/1.1/pagings`,
    update: id => `${config.apiBaseUrl()}confd/1.1/pagings/${id}`,
    updateCallers: id => `${config.apiBaseUrl()}confd/1.1/pagings/${id}/callers/users`,
    updateMembers: id => `${config.apiBaseUrl()}confd/1.1/pagings/${id}/members/users`,
    delete: id => `${config.apiBaseUrl()}confd/1.1/pagings/${id}`,
  },

  webhooks: {
    getAll: `${config.apiBaseUrl()}webhookd/1.0/subscriptions`,
    getLogs: uuid => `${config.apiBaseUrl()}webhookd/1.0/subscriptions/${uuid}/logs`,
    get: uuid => `${config.apiBaseUrl()}webhookd/1.0/subscriptions/${uuid}`,
    add: `${config.apiBaseUrl()}webhookd/1.0/subscriptions`,
    update: uuid => `${config.apiBaseUrl()}webhookd/1.0/subscriptions/${uuid}`,
    delete: uuid => `${config.apiBaseUrl()}webhookd/1.0/subscriptions/${uuid}`,
  },

  applications: {
    getAll: `${config.apiBaseUrl()}confd/1.1/applications`,
    get: uuid => `${config.apiBaseUrl()}confd/1.1/applications/${uuid}`,
    add: `${config.apiBaseUrl()}confd/1.1/applications`,
    update: uuid => `${config.apiBaseUrl()}confd/1.1/applications/${uuid}`,
    delete: uuid => `${config.apiBaseUrl()}confd/1.1/applications/${uuid}`,
  },

  globalSettings: {
    getDHCP: `${config.apiBaseUrl()}confd/1.1/dhcp`,
    updateDHCP: `${config.apiBaseUrl()}confd/1.1/dhcp`,
    getHighAvailability: `${config.apiBaseUrl()}confd/1.1/ha`,
    updateHighAvailability: `${config.apiBaseUrl()}confd/1.1/ha`,
    getHEP: `${config.apiBaseUrl()}confd/1.1/asterisk/hep/general`,
    updateHEP: `${config.apiBaseUrl()}confd/1.1/asterisk/hep/general`,
    getIAXGeneral: `${config.apiBaseUrl()}confd/1.1/asterisk/iax/general`,
    updateIAXGeneral: `${config.apiBaseUrl()}confd/1.1/asterisk/iax/general`,
    getCallNumberLimits: `${config.apiBaseUrl()}confd/1.1/asterisk/iax/callnumberlimits`,
    updateCallNumberLimits: `${config.apiBaseUrl()}confd/1.1/asterisk/iax/callnumberlimits`,
    getSCCP: `${config.apiBaseUrl()}confd/1.1/asterisk/sccp/general`,
    updateSCCP: `${config.apiBaseUrl()}confd/1.1/asterisk/sccp/general`,
    getVoicemailGeneral: `${config.apiBaseUrl()}confd/1.1/asterisk/voicemail/general`,
    updateVoicemailGeneral: `${config.apiBaseUrl()}confd/1.1/asterisk/voicemail/general`,
    getVoicemailZones: `${config.apiBaseUrl()}confd/1.1/asterisk/voicemail/zonemessages`,
    updateVoicemailZones: `${config.apiBaseUrl()}confd/1.1/asterisk/voicemail/zonemessages`,
    getConferenceBridge: `${config.apiBaseUrl()}confd/1.1/asterisk/confbridge/wazo_default_bridge`,
    updateConferenceBridge: `${config.apiBaseUrl()}confd/1.1/asterisk/confbridge/wazo_default_bridge`,
    getConferenceUser: `${config.apiBaseUrl()}confd/1.1/asterisk/confbridge/wazo_default_user`,
    updateConferenceUser: `${config.apiBaseUrl()}confd/1.1/asterisk/confbridge/wazo_default_user`,
    getFeatureGeneralOptions: `${config.apiBaseUrl()}confd/1.1/asterisk/features/general`,
    updateFeatureGeneralOptions: `${config.apiBaseUrl()}confd/1.1/asterisk/features/general`,
    getFeatureFeaturemapOptions: `${config.apiBaseUrl()}confd/1.1/asterisk/features/featuremap`,
    updateFeatureFeaturemapOptions: `${config.apiBaseUrl()}confd/1.1/asterisk/features/featuremap`,
    getFeatureApplicationmapOptions: `${config.apiBaseUrl()}confd/1.1/asterisk/features/applicationmap`,
    updateFeatureApplicationmapOptions: `${config.apiBaseUrl()}confd/1.1/asterisk/features/applicationmap`,
    addAsteriskCommand: `${config.apiBaseUrl()}amid/1.0/action/Command`,
    addAsteriskAction: action => `${config.apiBaseUrl()}amid/1.0/action/${action}`,
    getPJSIPGlobalOptions: `${config.apiBaseUrl()}confd/1.1/asterisk/pjsip/global`,
    updatePJSIPGlobalOptions: `${config.apiBaseUrl()}confd/1.1/asterisk/pjsip/global`,
    getPJSIPSystemOptions: `${config.apiBaseUrl()}confd/1.1/asterisk/pjsip/system`,
    updatePJSIPSystemOptions: `${config.apiBaseUrl()}confd/1.1/asterisk/pjsip/system`,
    getRTPGeneralOptions: `${config.apiBaseUrl()}confd/1.1/asterisk/rtp/general`,
    updateRTPGeneralOptions: `${config.apiBaseUrl()}confd/1.1/asterisk/rtp/general`,
    getRTPICEHostCandidateOptions: `${config.apiBaseUrl()}confd/1.1/asterisk/rtp/ice_host_candidates`,
    updateRTPICEHostCandidateOptions: `${config.apiBaseUrl()}confd/1.1/asterisk/rtp/ice_host_candidates`,
  },

  funckeys: {
    getTemplates: `${config.apiBaseUrl()}confd/1.1/funckeys/templates`,
    getTemplate: id => `${config.apiBaseUrl()}confd/1.1/funckeys/templates/${id}`,
    addTemplate: `${config.apiBaseUrl()}confd/1.1/funckeys/templates`,
    updateTemplate: id => `${config.apiBaseUrl()}confd/1.1/funckeys/templates/${id}`,
    deleteTemplate: id => `${config.apiBaseUrl()}confd/1.1/funckeys/templates/${id}`,
    getFunckey: (templateID, position) =>
      `${config.apiBaseUrl()}confd/1.1/funckeys/templates/${templateID}/${position}`,
    addReplaceFunckey: (templateID, position) =>
      `${config.apiBaseUrl()}confd/1.1/funckeys/templates/${templateID}/${position}`,
    deleteFunckey: (templateID, position) =>
      `${config.apiBaseUrl()}confd/1.1/funckeys/templates/${templateID}/${position}`,
    listUserFunckeys: userID => `${config.apiBaseUrl()}confd/1.1/users/${userID}/funckeys`,
    getUserFunckey: (userID, position) => `${config.apiBaseUrl()}confd/1.1/users/${userID}/funckeys/${position}`,
    addReplaceUserFunckey: (userID, position) => `${config.apiBaseUrl()}confd/1.1/users/${userID}/funckeys/${position}`,
    deleteUserFunckey: (userID, position) => `${config.apiBaseUrl()}confd/1.1/users/${userID}/funckeys/${position}`,
  },

  userAgentAssociate: {
    associateUserAndAgent: (userID, agentID) => `${config.apiBaseUrl()}confd/1.1/users/${userID}/agents/${agentID}`,
    disassociateUserAndAgent: userID => `${config.apiBaseUrl()}confd/1.1/users/${userID}/agents`,
  },
});

export default Addresses;
