/* eslint-disable no-shadow */
import React, { Component } from 'react';
import { Layout, Row, Col, Select } from 'antd';
import FeatherIcon from 'feather-icons-react';
import { Link } from 'react-router-dom';
import { Scrollbars } from 'react-custom-scrollbars';
import { ThemeProvider } from 'styled-components';
import { connect } from 'react-redux';
import propTypes from 'prop-types';
import MenueItems from './MenueItems';
import TopMenu from './TopMenu';
import { Div, SmallScreenAuthInfo, SmallScreenSearch, TopMenuSearch } from './style';
import HeaderSearch from '../components/header-search/header-search';
import AuthInfo from '../components/utilities/auth-info/info';
import { setNewTenant } from '../redux/tenant/actionCreator';
import strings from '../utility/strings';
import enLogoImage from '../static/img/workano-light-en.svg';
import faLogoImage from '../static/img/workano-light-fa.svg';
import moment from 'moment-jalaali';
import config from '../config/config';
import mainLogo from '../static/img/workano-light-logo.svg';
import { withRouter } from 'react-router-dom';

const { darkTheme } = require('../config/theme/themeVariables');
const { Option } = Select;
const { Header, Sider, Content } = Layout;
// const { darkMode } = config;
const ThemeLayout = WrappedComponent => {
  class LayoutComponent extends Component {
    constructor(props) {
      super(props);
      this.state = {
        collapsed: false,
        hide: true,
        searchHide: true,
        activeSearch: false,
      };
      this.updateDimensions = this.updateDimensions.bind(this);
    }

    componentDidMount() {
      window.addEventListener('resize', this.updateDimensions);
      this.updateDimensions();
    }

    componentWillUnmount() {
      window.removeEventListener('resize', this.updateDimensions);
    }

    updateDimensions() {
      this.setState({
        collapsed: window.innerWidth <= 1200 && true,
      });
    }

    render() {
      const { collapsed, hide, searchHide, activeSearch } = this.state;
      const { ChangeLayoutMode, rtl, topMenu, history } = this.props; // Destructure history from props

      const left = !rtl ? 'left' : 'right';
      const darkMode = ChangeLayoutMode;
      const toggleCollapsed = () => {
        this.setState({
          collapsed: !collapsed,
        });
      };

      const toggleCollapsedMobile = () => {
        if (window.innerWidth <= 990) {
          this.setState({
            collapsed: !collapsed,
          });
        }
      };

      const onShowHide = () => {
        this.setState({
          hide: !hide,
          searchHide: true,
        });
      };

      const toggleSearch = () => {
        this.setState({
          activeSearch: !activeSearch,
        });
      };

      const handleSearchHide = e => {
        e.preventDefault();
        this.setState({
          searchHide: !searchHide,
          hide: true,
        });
      };

      const footerStyle = {
        padding: '20px 30px 18px',
        color: 'rgba(0, 0, 0, 0.65)',
        fontSize: '14px',
        background: 'rgba(255, 255, 255, .90)',
        width: '100%',
        boxShadow: '0 -5px 10px rgba(146,153,184, 0.05)',
      };

      const SideBarStyle = {
        margin: 0,
        padding: '15px 15px 55px 0',
        overflowY: 'auto',
        height: '100vh',
        position: 'fixed',
        [left]: 0,
        zIndex: 1000,
      };

      const renderView = ({ style, ...props }) => {
        const customStyle = {
          marginRight: 'auto',
          [rtl ? 'marginLeft' : 'marginRight']: '-17px',
        };
        return <div {...props} style={{ ...style, ...customStyle }} />;
      };

      const renderThumbVertical = ({ style, ...props }) => {
        const { ChangeLayoutMode } = this.props;
        const thumbStyle = {
          borderRadius: 6,
          backgroundColor: ChangeLayoutMode ? '#ffffff16' : '#F1F2F6',
          [left]: '2px',
        };
        return <div style={{ ...style, ...thumbStyle }} props={props} />;
      };

      const renderTrackVertical = () => {
        const thumbStyle = {
          position: 'absolute',
          width: '6px',
          transition: 'opacity 200ms ease 0s',
          opacity: 0,
          [rtl ? 'left' : 'right']: '2px',
          bottom: '2px',
          top: '2px',
          borderRadius: '3px',
        };
        return <div style={thumbStyle} />;
      };

      const renderThumbHorizontal = ({ style, ...props }) => {
        const { ChangeLayoutMode } = this.props;
        const thumbStyle = {
          borderRadius: 6,
          backgroundColor: ChangeLayoutMode ? '#ffffff16' : '#F1F2F6',
        };
        return <div style={{ ...style, ...thumbStyle }} props={props} />;
      };

      const onTenantChange = uuid => {
        let tenant = this.props.tenants.find(x => x.uuid === uuid);
        if (tenant) {
          this.props.setCurrentTenant(tenant);
          window.location.reload(); // Force a full page reload
        }
      };

      return (
        <Div darkMode={darkMode}>
          <Layout className="layout">
            <div className="header-more">
              <Row>
                <Col md={0} sm={24} xs={24}>
                  <div className="small-screen-headerRight">
                    <SmallScreenSearch hide={searchHide} darkMode={darkMode}>
                      <HeaderSearch rtl={rtl} />
                    </SmallScreenSearch>
                    <SmallScreenAuthInfo hide={hide} darkMode={darkMode}>
                      <AuthInfo rtl={rtl} />
                    </SmallScreenAuthInfo>
                  </div>
                </Col>
              </Row>
            </div>
            <Layout>
              {!topMenu || window.innerWidth <= 991 ? (
                <ThemeProvider theme={darkTheme}>
                  <Sider width={280} style={SideBarStyle} collapsed={collapsed} theme={!darkMode ? 'light' : 'dark'}>
                    {!topMenu || window.innerWidth <= 991 ? (
                      <button type="link" onClick={toggleCollapsed} className="collapse-button">
                        {collapsed ? (
                          <FeatherIcon icon="chevron-right" color="#445BFF" />
                        ) : (
                          <FeatherIcon icon="chevron-left" color="#445BFF" />
                        )}
                      </button>
                    ) : null}
                    <Scrollbars
                      className="custom-scrollbar"
                      autoHide
                      autoHideTimeout={500}
                      autoHideDuration={200}
                      renderThumbHorizontal={renderThumbHorizontal}
                      renderThumbVertical={renderThumbVertical}
                      renderView={renderView}
                      renderTrackVertical={renderTrackVertical}
                    >
                      <p className="sidebar-nav-title">
                        <Link
                          className={topMenu && window.innerWidth > 991 ? 'striking-logo top-menu' : 'striking-logo'}
                          to="/dashboard"
                        >
                          {collapsed ? (
                            <img src={mainLogo} alt="" />
                          ) : (
                            <img src={config.currentLang.culture === 'fa-IR' ? faLogoImage : enLogoImage} alt="" />
                          )}
                        </Link>
                      </p>

                      <MenueItems
                        topMenu={topMenu}
                        rtl={rtl}
                        toggleCollapsed={toggleCollapsedMobile}
                        darkMode={darkMode}
                      />
                    </Scrollbars>
                    <span className="admin-footer__copyright">
                      <span>©</span>
                      {strings().connectino} - {moment().format('YYYY')}
                    </span>
                  </Sider>
                </ThemeProvider>
              ) : null}
              <Layout className="atbd-main-layout">
                <Content>
                  <Header
                    style={{
                      position: 'sticky',
                      width: '100%',
                      top: 0,
                      [!rtl ? 'left' : 'right']: 0,
                    }}
                  >
                    <Row>
                      <Col
                        lg={!topMenu ? 4 : 3}
                        sm={6}
                        xs={12}
                        className="align-center-v navbar-brand"
                        style={{ justifyContent: 'center', paddingRight: 10, paddingLeft: 10 }}
                      >
                        {topMenu && window.innerWidth > 991 ? (
                          <TopMenu />
                        ) : (
                          <Select
                            allowClear
                            value={this.props.currentTenant.uuid}
                            style={{ width: '100%' }}
                            onChange={onTenantChange}
                            title={strings().tenant.tenant}
                            placeholder={strings().loading}
                            loading={this.props.loadingTanent}
                            size="middle"
                            className="tenant-menu"
                          >
                            {this.props.tenants.map((t, idx) => (
                              <Option key={idx} value={t.uuid}>
                                {t.name}
                              </Option>
                            ))}
                          </Select>
                        )}
                        {topMenu && window.innerWidth > 991 ? <TopMenu /> : null}
                      </Col>

                      <Col
                        lg={!topMenu ? 14 : 15}
                        md={8}
                        sm={0}
                        xs={0}
                        style={{
                          display: 'flex',
                          justifyContent: 'flex-end',
                          alignItems: 'center',
                        }}
                      ></Col>

                      <Col lg={6} md={10} sm={0} xs={0}>
                        {topMenu && window.innerWidth > 991 ? (
                          <TopMenuSearch>
                            <div className="top-right-wrap d-flex">
                              <AuthInfo />
                            </div>
                          </TopMenuSearch>
                        ) : (
                          <AuthInfo />
                        )}
                      </Col>

                      <Col md={0} sm={18} xs={12}>
                        <>
                          <div className="mobile-action">
                            <Link className="btn-auth" onClick={onShowHide} to="#">
                              <FeatherIcon icon="more-vertical" />
                            </Link>
                          </div>
                        </>
                      </Col>
                    </Row>
                  </Header>
                  <WrappedComponent {...this.props} />
                </Content>
              </Layout>
            </Layout>
          </Layout>
        </Div>
      );
    }
  }
  const mapDispatchToProps = dispatch => {
    return {
      // dispatching plain actions
      setCurrentTenant: data => setNewTenant(data)(dispatch),
    };
  };

  const mapStateToProps = state => {
    return {
      ChangeLayoutMode: state.ChangeLayoutMode.data,
      rtl: state.ChangeLayoutMode.rtlData,
      topMenu: state.ChangeLayoutMode.topMenu,
      loadingTanent: state.tenant.loading,
      tenants: state.tenant.tenants,
      currentTenant: state.tenant.currentTenant,
    };
  };

  LayoutComponent.propTypes = {
    ChangeLayoutMode: propTypes.bool,
    rtl: propTypes.bool,
    topMenu: propTypes.bool,
    changeRtl: propTypes.func,
    changeLayout: propTypes.func,
    changeMenuMode: propTypes.func,
    history: propTypes.object.isRequired, // Add history to propTypes
  };

  return withRouter(connect(mapStateToProps, mapDispatchToProps)(LayoutComponent));
};
export default ThemeLayout;
